import styled from 'styled-components'

export const BrandingPageDirection = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const BrandContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    margin: 15px 0;
    @media (max-width: 768px) {
        align-items: center;
    }
`

export const TitlePage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 36px;
    padding: 0 13px;
    h1 {
        font-weight: 600;
        font-size: 32px;
        box-sizing: content-box;
        line-height: 38px;
        color: #3ae29b;
        margin-bottom: 14px;
    }
    span {
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: #3c4b61;
    }
    @media (max-width: 1270px) {
        width: 80%;
        align-items: flex-start;
        margin: 15px auto;
    }
    @media (max-width: 450px) {
        width: 85%;
    }
`

export const MainContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`

export const BrandingFooter = styled.div`
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: 80px;
    border-top: 1px solid #e3e7ff;
    padding: 0 23px;
    background-color: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`

export const ContentBlockContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 380px;
    height: max-content;
    background: #fafaff;
    border: 1px solid #e3e7ff;
    border-radius: 12px;
    margin: 14px;
    padding: 25px 28px;
    h2 {
        margin-bottom: 20px;
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
        color: #3c4b61;
    }
    @media (max-width: 435px) {
        width: 85%;
    }
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
`

export const Description = styled.span`
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #3c4b61;
    margin-bottom: 6px;
`

export const Marginer = styled.div.attrs((props) => ({}))<{
    height: string
}>`
    width: inherit;
    height: ${(props) => (props.height ? props.height : 'inherit')};
`

export const FontsList = styled.div`
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    border-radius: 10px;
    padding: 5px 0;
    transition: background 0.15s ease-in-out;
    & > .fonts_name {
        flex: 1;
        cursor: pointer;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        background: none;
        color: #717f95;
    }
    & > .MuiSvgIcon-root {
        color: #717f95;
        cursor: pointer;
    }
    &:hover {
        background: rgba(100, 120, 249, 0.15);
    }
`

export const ColorsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    @media (max-width: 450px) {
        align-items: center;
    }
`

export const FontPickerContainer = styled.div`
    width: 100%;
    div[id^='font-picker'] {
        display: none;
        width: 100%;
        font-size: 14px;
        color: #717f95;
        background-color: #fff;
        background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23666666%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
        background-repeat: no-repeat;
        background-position: right 20px top 50%;
        background-size: 10px auto;
        border: 1px solid #dfe2f2;
        border-radius: 10px;
        outline: none;
        box-shadow: none;
    }
    div[id^='font-picker'] .dropdown-button {
        height: 38px;
        padding: 0.5em;
        background-color: transparent;
    }
    div[id^='font-picker'] .dropdown-button p {
        max-width: 200px;
        padding-right: 2.25em;
        overflow: hidden;
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    div[id^='font-picker'] .dropdown-button:hover,
    div[id^='font-picker'] .dropdown-button:focus {
        background-color: transparent;
        cursor: pointer;
        outline: unset;
    }

    div[id^='font-picker'] .dropdown-button:focus {
        outline: none;
    }

    div[id^='font-picker'] .dropdown-icon {
        display: none;
    }

    div[id^='font-picker'] ul {
        margin-top: 5px;
        border-radius: 10px;
        background-color: #fff;
        box-shadow: 0 1px 0.125rem rgba(0, 0, 0, 0.25);
    }

    div[id^='font-picker'] ul li button:hover,
    div[id^='font-picker'] ul li button:focus {
        background-color: #eee;
    }
`

export const BackgroundImageContainer = styled.div`
    position: absolute;
    top: 6%;
    left: 2px;
    width: 100%;
    height: 100%;
    &:hover .fab-buttons {
        opacity: 1;
        visibility: visible;
    }
    &:hover .fab-buttons__link {
        transform: scaleY(1) scaleX(1) translateY(-10px) translateX(0px);
    }
    img {
        border-radius: 5px;
        width: 90%;
        height: 90%;
    }
    img:hover + .fab-buttons .fab-buttons__link:before {
        visibility: visible;
        opacity: 1;
        transform: scale(1);
        transform-origin: right center 0;
        transition-delay: 0.3s;
    }
    .MuiSvgIcon-root {
        color: #fff;
    }
    .fab-buttons {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 20px;
        list-style: none;
        margin: 0;
        padding: 0;
        opacity: 0;
        visibility: hidden;
        transition: 0.2s;
    }
    .fab-action-button__icon {
        display: inline-block;
        width: 56px;
        height: 56px;
        background: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjZmZmZmZmIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiIGZpbGw9Im5vbmUiLz4KICAgIDxwYXRoIGQ9Ik0xOCAxNi4wOGMtLjc2IDAtMS40NC4zLTEuOTYuNzdMOC45MSAxMi43Yy4wNS0uMjMuMDktLjQ2LjA5LS43cy0uMDQtLjQ3LS4wOS0uN2w3LjA1LTQuMTFjLjU0LjUgMS4yNS44MSAyLjA0LjgxIDEuNjYgMCAzLTEuMzQgMy0zcy0xLjM0LTMtMy0zLTMgMS4zNC0zIDNjMCAuMjQuMDQuNDcuMDkuN0w4LjA0IDkuODFDNy41IDkuMzEgNi43OSA5IDYgOWMtMS42NiAwLTMgMS4zNC0zIDNzMS4zNCAzIDMgM2MuNzkgMCAxLjUtLjMxIDIuMDQtLjgxbDcuMTIgNC4xNmMtLjA1LjIxLS4wOC40My0uMDguNjUgMCAxLjYxIDEuMzEgMi45MiAyLjkyIDIuOTIgMS42MSAwIDIuOTItMS4zMSAyLjkyLTIuOTJzLTEuMzEtMi45Mi0yLjkyLTIuOTJ6Ii8+Cjwvc3ZnPg==')
            center no-repeat;
    }
    .fab-buttons__item {
        display: block;
        text-align: center;
        margin: 15px 0;
    }
    .fab-buttons__link {
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        text-decoration: none;
        background-color: rgba(0, 0, 0, 0.8);
        border-radius: 50%;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
            0 3px 1px -2px rgba(0, 0, 0, 0.2);
        transform: scaleY(0.5) scaleX(0.5) translateY(0px) translateX(0px);
        -moz-transition: 0.3s;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
    }
    [data-tooltip]:before {
        top: 50%;
        margin-top: -11px;
        font-weight: 600;
        border-radius: 2px;
        background: #585858;
        color: #fff;
        content: attr(data-tooltip);
        font-size: 12px;
        text-decoration: none;
        visibility: hidden;
        opacity: 0;
        padding: 4px 7px;
        margin-right: 12px;
        position: absolute;
        transform: scale(0);
        right: 100%;
        white-space: nowrap;
        transform-origin: top right;
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    }
    [data-tooltip]:hover:before {
        visibility: visible;
        opacity: 1;
        right: -150px;
        transform: scale(1);
        transform-origin: right center 0;
    }
`

export const Container = styled.div`
    position: relative;
    display: flex;
    width: 38px;
    height: 38px;
    background: none;
    border: 1px solid #6478f9;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
`
export const CurrentColor = styled.div.attrs((props) => ({}))<{
    background?: string
}>`
    width: 32px;
    height: 32px;
    position: relative;
    background-color: ${(props) => props.background && props.background};
    background-size: cover;
    border: 1px solid #dfe2f2;
    border-radius: 6px;
    background-position: center;
    z-index: 10;
`

export const ColourPickerWrapper = styled.div.attrs((props) => ({}))<{
    position?: 'right'
}>`
    position: absolute;
    top: -325px;
    left: -15px;
    z-index: 100;
    .sketch-picker {
        position: fixed;
        bottom: 100px;
    }
    @media (max-width: 820px) {
        top: 45px;
        left: ${(props) => (props.position ? '-120px' : '-30px')};
    }
`

export const ColourPicker = styled.div`
    position: fixed;
    inset: 0;
`

export const UploadContainer = styled.div`
    width: 100%;
    margin-top: 11px;
    background: #6478f9;
    border-radius: 8px;

    input {
        display: none;
    }
    label {
        width: 100%;
        color: #fff;
    }
    .MuiButton-label {
        text-transform: none;
    }
`

export const ThemeColorsWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 19px;
`

export const ButtonColorsWrapper = styled.div`
    display: flex;
    align-items: center;
`
export const iconStyle = { marginLeft: '4px', cursor: 'pointer' }

export const ButtonGroup = styled.div`
    display: flex;
    input[id^='uploadBackground'] {
        display: none;
    }
    .MuiButton-outlinedPrimary {
        outline: none;
        height: 32px;
        width: 100%;
        text-transform: none;
        border: 1px solid #dfe2f2;
        border-radius: 6px;
        font-size: 12px;
        color: #717f95;
        &:first-child {
            margin-right: 7px;
        }
        &:hover {
            border: 1px solid #dfe2f2;
        }
        svg {
            margin-right: 7px;
        }
        @media (max-width: 450px) {
            .MuiButton-label {
                display: -webkit-inline-box;
            }
        }
    }
`

export const SearchBlock = styled.div`
    display: flex;
    position: relative;
    & > div {
        margin-bottom: 0;
    }
    & > svg {
        position: absolute;
        top: 8px;
        right: 10px;
    }
`

export const AssetsContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    h1 {
        margin: 0 auto;
        font-size: 22px;
        color: #717f95;
    }
`

export const Title = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 23px;
    & > h2 {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        color: #3c4b61;
    }
    & > svg {
        cursor: pointer;
    }
`

export const SelectCategory = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    & > div:first-child,
    p {
        margin: 0;
        margin-right: 15px;
    }
    &:first-child {
        flex: 1;
    }
    & > label {
        margin-bottom: 6px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #3c4b61;
    }
    & > .MuiFormControl-root {
        margin-bottom: 10px;
        height: 38px;

        & > .MuiInputBase-root {
            height: inherit;
            & > .MuiOutlinedInput-notchedOutline {
                display: none;
            }
            & > .MuiSelect-root {
                display: flex;
                align-items: center;
                height: inherit;
                padding: 0;
                padding-left: 10px;
                border-radius: 10px;
                border: 1px solid #dfe2f2;
            }
        }
    }
    & > .MuiLink-underlineHover {
        cursor: pointer;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #ff7d34;
    }
    @media (max-width: 520px) {
        align-items: flex-start;
        flex-direction: column;
        & > div {
            margin-top: 15px;
        }
    }
    @media (max-width: 480px) {
        height: 100%;
    }
`

export const CardContainer = styled.div`
    height: 135px;
    width: 135px;
    cursor: pointer;
    background: #fafaff;
    border: 1px solid #dfe2f2;
    border-radius: 10px;
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: all 0.15s ease-in-out;
    margin-top: 10px;
    &:hover {
        transform: translateY(-5px);
        box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 40%);
    }
`
export const ImageContainer = styled.div`
    position: absolute;
    overflow: hidden;
    height: 100px;
    width: 120px;
    top: 5px;
    & > img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: cover;
        width: inherit;
        height: inherit;
        border-radius: 5px;
    }
`
export const CardTitle = styled.div`
    position: absolute;
    bottom: 5px;
    padding: 0 10px;
    width: 100%;
    & > h4 {
        white-space: nowrap;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        text-align: center;
        font-size: 10px;
        line-height: 12px;
        color: #717f95;
    }
`

export const SocialContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    &:last-child {
        & > div {
            margin-bottom: 0;
        }
    }
`

export const SocialImageContainer = styled.div`
    width: 28px;
    height: 28px;
    margin-right: 12px;
    &:not(:last-child) {
        margin-bottom: 18px;
    }
`
