
interface PromiseWithCancel<T> extends Promise<T> {
    cancel: () => void
}

const PromiseFetch = async (method: string, api:string, payload?: {[key:string]:any}) => {
 const controller = new AbortController()
    const signal = controller.signal

    const promise = new Promise(async (resolve, reject) => {
        try {
            let res
            if(method === 'GET'){
                res = await fetch(api, {
                    method,
                    signal,
                })
            } else {
                res = await fetch(api, {
                    method,
                    body: JSON.stringify(payload),
                    signal,
                })
            }
            
            if (!res.ok) {
                throw new Error(res.statusText)
            }

            const data = await res.json()

            resolve(data)
        } catch (err: any) {
            if (isAbortError(err)) {
                reject(new Error('Request cancelled'))
            } else {
                reject(new Error(err.message))
            }
        }
    })
    ;(promise as PromiseWithCancel<any>).cancel = () => {
        controller.abort()
    }
    return promise as PromiseWithCancel<any>
}



function isAbortError(error: any): error is DOMException {
    if (error && error.name === 'AbortError') {
        return true
    }
    return false
}


export {PromiseFetch}